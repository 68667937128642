.top-nav .nav-item {
    padding: 6px 35px;
    border: .5px solid;
    margin: 0 -.5px;
    font-weight: 600;
}

.navbar-bg {
    background-color: #fdffbc;
}

.top-nav .nav-link {
    color: #000;
    font-weight: 600;
}

.top-nav .nav-link .active {
    color: #0038a1;
    font-weight: 600;
}

@media only screen and (max-width: 1280px) {
    .top-nav .nav-item {
        padding: 6px 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .top-nav .nav-item {
        padding: 6px 16px;
    }
}

/* dark section */
body.dark .navbar-bg {
    background-color: #252522;
}
body.dark .navbar-bg .dropdown-menu {
    background-color: #252522;

}
body.dark .navbar-bg .dropdown-menu a{
    color: #fff;
}
body.dark .navbar-bg .dropdown-menu a:hover{
    color: #252522;
}

body.dark .top-nav .nav-link {
    color: var(--dark-color);
}

/* body.dark .top-nav .nav-item {
    border: 1px solid;
} */