.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.box {
  box-shadow: 0px 0px 10px #ddd;
  padding: 80px 50px;
  border-radius: 20px;
}

.btn {
  width: 100%;
  background-color: crimson;
  color: white;
  border: 0px none;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ddd;
}
