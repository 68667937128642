.content-wrapper {
  background: #9e9e9e;
}

.SignIn-wrapper {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.card-signIn {
  height: auto;
  width: 60%;
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: hsla(0, 0%, 100%, 0.8);
}
.signIn-top {
  background-color: #00205b;
}

.login-logo img {
  height: 250px;
}

@media only screen and (max-width: 480px) {
  .card-signIn {
    border: 1px solid #cdd4e0;
    width: 360px;
    height: auto;
    padding: 0px 12px;
  }
  .login-logo img {
    height: 200px;
  }
  .login-logo {
    justify-content: center !important;
  }
}

@media only screen and (min-width: 991px) {
  .card-signIn {
    min-width: 950px;
  }
}
