a {
  color: #000000;
}

a:hover {
  color: #000000;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  background-color: #00205c;
  border-color: #00205c;
}

.add_device_btn {
  /* margin-left: 449px; */
  cursor: pointer;
}

.btn-info {
  background: #00205b;
  border-color: #00205b;
  color: #fff;
}

.btn-info:hover {
  background: #033ba2 !important;
  border-color: #033ba2 !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm {
  background-color: #dc3545 !important;
  border: #dc3545 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.eUeqdG {
  font-size: 15px;
}

.fullscreen-enabled {
  background-color: #fff;
}

.vh60 {
  min-height: 60vh;
}

.description p {
  margin: 0px !important;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 15px !important;
}

.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 14px !important;
}

.table {
  color: #000000 !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #000000 !important;
}

.dt-buttons {
  margin-bottom: 10px;
}

table.dataTable thead th,
table.dataTable thead td {
  font-size: 16px;
  font-weight: 600;
}

.minmax {
  width: 150px;
  padding: 10px;
}

option:disabled {
  color: #c5b9b9;
}

.position-relative {
  position: relative;
}

.imagePreview {
  text-align: center;
  position: relative !important;
}

.imagePreview .badge {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s ease-in;
}

.imagePreview:hover .badge {
  opacity: 1;
}

img {
  max-width: 100% !important;
}

svg {
  background-color: transparent !important;
}

.action {
  // padding: 0 !important;
  background-color: red;
}

.avater_profile {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.avater_table {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.ant-select {
  padding: 0px;
}

.ant-select-selection-placeholder {
  color: black !important;
}

body.dark .ant-select-selection-placeholder {
  color: var(--dark-color) !important;
}

body.dark .ant-select-selector {
  background-color: var(--dark-bg) !important;
  color: var(--dark-color) !important;
  border-color: var(--dark-bg) !important;
}

::placeholder,
// select,
.alert {
  text-transform: capitalize;
}

.bg-blue {
  background-color: #00205b;
}

.clickable {
  cursor: pointer;
}

.notSelect {
  user-select: none;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;

  &:not(.react-grid-placeholder) {
    background: #f5f5f5;
  }

  &.cssTransforms {
    transition-property: transform;
  }

  &.resizing {
    z-index: 1;
    will-change: width, height;
  }

  &.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  &.react-grid-placeholder {
    background: blue;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  & > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  & > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  &:not(.react-grid-placeholder) {
    background: whitesmoke;
  }
}

.clickbtn {
  cursor: pointer;
}

.bg-layout {
  background-color: #e3e2e7;
}

.bg-layout-dark {
  background-color: var(--dark-bg);
}

.dark-layout-cark {
  background-color: #2a2a2a !important;
  border: 0 !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.circle-info {
  background-color: black;
  color: white;
  border-radius: 50%;
  display: inline-flex;
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
}
